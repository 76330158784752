import React, { Component } from 'react';
import { Modal, ModalBody, Row, Col, ModalHeader } from 'reactstrap';
import $ from 'jquery';
import '../../src/assets/js/menuCustom.js';
import logoBewise from './../assets/images/bewise-logo-new.png';
import logoBewise2 from './../assets/images/bewiselogov1.png';
import config from '../API/DomainName';
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import {
  social_login, userLogin, userSignUp, otpVerify, getSearchCity
} from '../API/FetchCall';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
//import { LinkedIn } from 'react-linkedin-login-oauth2';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import ReactGA from 'react-ga';
import Recaptcha from 'react-recaptcha';
import ReactTooltip from "react-tooltip";

import Autosuggest from 'react-autosuggest';

import { Helmet } from 'react-helmet';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialLinkedin,
  IResolveParams,
} from 'reactjs-social-login'

import 'react-tabs/style/react-tabs.css';

//import theme from "./../assets/css/theme.css";

import {
  BASE_URL, GOOGLE_CAPTCHA_SITE_KEY, SCREEN_SIZE, GOOGLE_LOGIN_CLIENT_ID,
  LINKEDIN_CLIENT, FACBEOOK_APP_ID, LINKEDIN_SECRET, ADMIN_URL
} from '../constant';

const REDIRECT_URI = window.location.href;

const theme = {
  container: {
    position: 'relative',
  },
  input: {
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '10px',
    fontSize: '12px'
  },
  suggestionsContainer: {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    borderRadius: '4px',
    backgroundColor: 'antiquewhite',
    listStyle:"none",
    paddingRight: 0
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px',
    fontSize:'12px'
  },

  suggestionHighlighted: {
    backgroundColor: '#eee',
  },
};


class Newlogin extends Component {
  constructor(props) {
    super(props);
    this.state = {

      showpop: false,
      tabActive: false,
      signin_tab: false,
      signup_tab: false,
    };
  }


  componentDidMount() {

  }
  closeAuthPop = (e) => {
    e.preventDefault();
    this.setState({
      showpop: false,
      signin_tab: false,
      signup_tab: false,
    });
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.login === true) {
      this.setState({
        showpop: true,
        signin_tab: true
      })
    } else if (nextProps.signup === true) {
      this.setState({
        showpop: true,
        signup_tab: true
      })
    }
  }
  render() {
    const { tabActive } = this.state;
    const { value, suggestions, data } = this.state;
    let isMobile = false;
    if (SCREEN_SIZE <= 767) {
      isMobile = true;
    }
    let activetabs = isMobile ? -1 : (this.state.signup_tab ? 1 : 0);
    console.log('activetabs', activetabs)
    return (
      <React.Fragment>
        <div className=''>
          <Modal className='d-flex new-login-pop' isOpen={this.state.showpop} size='xl'>
            <div className='row align-item-flex-start'>
              {isMobile && (
                <div className='col-6'>
                  <div className="login-ad-logo">
                    <img src={logoBewise2} alt="bewiselogo" className="mainlogoimg" style={{ width: "100%", marginRight: "2px" }} />
                  </div>
                </div>
              )}
              <div className={isMobile ? 'col-6' : 'col-12'}>
                <button
                  type='button'
                  className='close new-signup-closebtn'
                  onClick={this.closeAuthPop}
                >
                  {/* <img src={BASE_URL + 'assets/icons/close.svg'} alt='close' /> */}
                  <p className='mb-0'>Close</p>
                </button>
              </div>
            </div>
            <Row className='no-margin  new-login justify-content-center'>
              <Col
                md={{ size: 6 }}
                className='m-signin d-none d-md-block'>
                <div className='content-login login-logo'>
                  <div className="login-content-inner">
                    <img src={logoBewise2} alt="bewiselogo" className="mainlogoimg" style={{ width: "100%", marginRight: "2px" }} />
                  </div>
                </div>
                <div className='row new-login-left'>
                  <div className='col-12 p-0 '>
                    <img src='/assets/icons/login-pop.webp' title="Login" alt="loginbannerpop" className='login-banner' />
                  </div>
                  <div className='col-12 p-0 mt-4'>
                    <img src='/assets/images/ad-banner/login-banner-ad-web.png' alt="loginbanner" className='login-banner login-banner-ad' />
                  </div>
                </div>
              </Col>
              <Col sm={{ size: 12 }} md={{ size: 6 }} className=''>
                <div className='col-md-12 form-sec pr-0 signup-right'>
                  
                  <div className='margintopA1 cus-rl-tab'>

                  <div>
                    <small className='newlogin-email-text font14 fontweight600'>Educators and institutions - <a href={`${ADMIN_URL}joinus`}>click here</a></small>
                  </div>
                  
                    <Tabs defaultIndex={1} onSelect={(index) => { this.setState({ tabActive: true }) }}>
                      <TabList className={'custome-tab-login row align-items-center justify-content-center p-0 mb-0'}>
                        <Tab >Parent Sign In</Tab>
                        <Tab>Parent Sign Up</Tab>
                      </TabList>
                      {/* isMobile && !tabActive && (
                        <div className='row new-login-left mob-login-banner'>
                          <div className='col-12 p-0 '>
                            <img src='/assets/login-banner-mob-ad.webp' alt="loginbanner" className='login-banner login-banner-ad' />
                          </div>
                        </div>
                      ) */}
                      <TabPanel>
                        <Login />
                      </TabPanel>
                      <TabPanel>
                        <Signup />
                      </TabPanel>
                    </Tabs>


                  </div>
                </div>


              </Col>
            </Row>
          </Modal>
        </div>

      </React.Fragment >
    );
  }
}
class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      l_emailid: '',
      l_password: '',
      showPassword: false
    };
  }


  componentDidMount() {
    let qrystr = window.location.search;
    const urlParams = new URLSearchParams(qrystr);
    let urlType = urlParams.get('type');
   
   /* if (urlType == 'linkedLogin') {
      let linkedState = urlParams.get('state');
      let authToken = urlParams.get('code');
      console.log('authTokenlinked', authToken);
      linkedinAuth(authToken, linkedState);
    } */
  }


   responseLinkedin = (res) =>{

    console.log("linkedin Data", res);

    this.linkedinAuth(res.access_token);

    /*
    let profile = res.data;
    let emailid = res.data.emailelement.elements[0]['handle~'].emailAddress;
    var regData = JSON.stringify({
      token: profile.token,
      login_type: 4,//linkedin
      name: profile.first_name+" "+profile.last_name,
      email: emailid,
    });
     
    this.socialLogin(regData);
*/

  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleShowPassword() {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }



  handlelogin = (e) => {
    e.preventDefault();
    let regData = JSON.stringify({
      login_type: 1,
      email_id: this.state.l_emailid,
      password: this.state.l_password,
    });
    let toastr = '';
    userLogin(regData).then((response) => {
      if (response.status == 1) {
        ReactGA.event({
          category: "loginuser",
          action: "login | Success",
          label: "loginuser",
        });
        toastr = window.toastr.success;
        toastr('Success', 'Login successful!..', {
          positionClass: "toast-bottom-center",
          containerId: "toast-bottom-center"
        });
        this.setState({
          showModalLogin: false,
          showModalSignup: false,
          l_emailid: '',
          l_password: '',
          authe: true,
          logged_name: response.data.first_name,
        })
        let userdata = {
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          user_id: response.data.user_id,
          email_id: response.data.email_id,
          mobile_no: response.data.mobile_no,
          login_type: response.data.login_type,
        }
        localStorage.setItem("logged_auth", true);
        localStorage.setItem('myData', JSON.stringify(userdata));
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } else {
        ReactGA.event({
          category: "loginuser",
          action: "login | failure",
          label: "loginuser",
        });
        toastr = window.toastr.error; //warning
        let er = response.msg ? response.msg : response.errors[0].msg
        toastr = window.toastr.error;
        toastr(er, toastr, {
          positionClass: "toast-bottom-center",
          containerId: "toast-bottom-center"
        });
      }
    });
  };



  render() {
    return (
      <React.Fragment>
        <div className=''>
          {getSocialMedia()}
          <div>
            <form id='loginForm' method='post'
              onSubmit={this.handlelogin}
              autoComplete='off'>
              <div className='form-group has-feedback'>

                <input
                  type='text'
                  name='l_emailid'
                  placeholder='Email ID/Mobile Number'
                  value={this.state.l_emailid}
                  required
                  onChange={this.handleChange}
                  className='form-control'
                />
              </div>
              <div className='form-group has-feedback'>
                <input
                  value={this.state.l_password}
                  onChange={this.handleChange}
                  placeholder='Password'
                  type={this.state.showPassword ? 'text' : 'password'}
                  required
                  name='l_password'
                  className='form-control'
                />
                <span
                  toggle='#password-field'
                  className={this.state.showPassword ? 'fa fa-fw fa-eye field-icon' : 'fa fa-fw fa-eye-slash field-icon'}
                  onClick={() => {
                    this.handleShowPassword();
                  }}
                ></span>
                <p className='new-pass mt-2'>
                  <span className='r-c5gf'>
                    <a href="/forgot" className='r-c5gf newlogin-email-text'> Forgot Password</a>
                  </span>
                </p>
              </div>
              <div className='row d-flex justify-content-end align-items-center form-group has-feedback mb-4'>
                <div className='col-md-6'>
                  <input
                    type='submit'
                    name='submit'
                    value='LOGIN'
                    className='submit btn btn-primary btn-block btn-flat bdr-15 newlogin-btn float-right'
                  />
                </div>
              </div>
            </form>
          </div>
          { /*
           <div>
           <small className='newlogin-email-text'>For Individual partner login <a href={`${ADMIN_URL}individual`}><b>click here</b></a></small>
           <br/>
           <small className='newlogin-email-text'>For Institutional partner login <a href={`${ADMIN_URL}institution`}><b>click here</b></a></small>
           </div> */ 
          }
         

        </div>

      </React.Fragment >
    );
  }
}
function getSocialMedia() {
  return (
    <React.Fragment>
      <div className="newlogin row justify-content-center align-items-center social-icons mt-4">
        <div className='has-feedback col-2'>
          <div className="d-inline-block ">
            <FacebookLogin
              appId={FACBEOOK_APP_ID}
              fields="name,email,picture"
              callback={responseFacebook}
              textButton=""
              cssClass="my-facebook-button-class"
              icon="fa-facebook"
              isMobile={false}
              render={renderProps => (
                <button
                  className='btn btn-info btn-block btn-flat custom-fb-icon'
                  onClick={renderProps.onClick}>
                </button>
              )}
            />
          </div>
        </div>
        <div className='has-feedback col-2'>
          <GoogleLogin
            clientId={GOOGLE_LOGIN_CLIENT_ID}
            render={renderProps => (
              <div onClick={renderProps.onClick} disabled={renderProps.disabled}
                className='c-pointer'>
                <img src="/assets/icons/login-google.png" alt='google' style={{ width: '23px' }} />
              </div>
            )}
            onSuccess={prepareLoginButton}
            onFailure={prepareLoginButton}
            cookiePolicy={'single_host_origin'}
          />

        </div>
        <div className='has-feedback col-2'>

        <LoginSocialLinkedin
                  isOnlyGetToken
                  client_id={process.env.REACT_APP_LINKEDIN_CLIENT || ''}
                  client_secret={process.env.REACT_APP_LINKEDIN_SECRET || ''}
                  redirect_uri={REDIRECT_URI}
                  scope={'openid profile email'}
                  onResolve={({ provider, data }) => {
                    //console.log(data);
                    this.responseLinkedin(data);
                    //setProvider(provider)
                    //setProfile(data)
                  }}
                  onReject={(err) => {
                  console.log(err)
                  }}
                  >
                  <div className='google-plus-icon linkedin-icon c-pointer'>
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </div>
          </LoginSocialLinkedin>
         
        </div>
      </div>
      <p className='align-center u7c3b mb-4 mt-4 newlogin-email-text'><b>Or continue with emails</b></p>
    </React.Fragment>
  )
}
class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
      city_selected:'',
      showsuggest: false,
      defaultSuggest: '',
      verficationPopup:false,
      reg_email: '',
      value:'',
      first_name: '',
      last_name: '',
      reg_mobile: '',
      reg_city: '',
      reg_password: '',
      reg_confirmpassword: '',
      child_data: [{ child_name: "", child_age: "", child_scl: "", child_city: "" }],
      showPass: false,
      showCPass: false,
      isValidCaptcha: false,
      otp:'',
      verfiying:false,
      otpverified:false
    };

    this.validator = new SimpleReactValidator({
      validators: {
        password_custome: {  // name the rule
          message: 'The :attribute must be a Min of 8 characters in length – One Capital Letter, One Number, One Character.',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^(?=.*\d)(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/) && params.indexOf(val) === -1
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
          required: true  // optional
        },
        password_customeE: {  // name the rule
          message: 'The :attribute must be a Min of 8 characters in length – One Capital Letter, One Number, One Character.',
          rule: (val, params, validator) => {
            if (val) {
              return validator.helpers.testRegex(val, /^(?=.*\d)(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/) && params.indexOf(val) === -1
            }
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
          required: true  // optional
        },
        confirmpassword: {  // name the rule
          message: 'The :attribute must be Same as Password.',
          rule: (val, params, validator) => {
            if (val != this.state.reg_password) {
              return false;
            }
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
          required: true  // optional
        }
      },
      autoForceUpdate: this
    });

    this.handleSubmit  =this.handleSubmit.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);

  } 


  componentDidMount() {
    let qrystr = window.location.search;
    const urlParams = new URLSearchParams(qrystr);
    let urlType = urlParams.get('type');
    if (urlType == 'linkedLogin') {
      let linkedState = urlParams.get('state');
      let authToken = urlParams.get('code');
      console.log('authTokenlinked', authToken);
      linkedinAuth(authToken, linkedState);
    }
  }


  linkedinAuth = (token) => {

    var authpost = JSON.stringify({
      grant_type: 'authorization_code',
      code: token,
      client_id: LINKEDIN_CLIENT,
      client_secret: LINKEDIN_SECRET,
      redirect_uri: 'https://www.bewise.in/?type=linkedLogin',
    });
    
    let toastr = '';
    fetch('https://api.bewise.in/api/user/auth/linkedAuth', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: authpost,
    }).then(res => res.json()).then(res => {
              
      
      let profile = res.data;

      var regData = JSON.stringify({
        login_type: 4,//linkedin
        name: profile.first_name+" "+profile.last_name,
        email: profile.email,
      });
       
      this.socialLogin(regData);

       
    })

  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  toggleChange = () => {
    this.setState({
      agree_data: !this.state.agree_data,
    });
  }
  verifyCallback = (response) => {
    if (response) {
      this.setState({
        isValidCaptcha: true,
      });
    }

  }
  handleShowPassword(type) {
    if (type == 1)
      this.setState((prevState) => ({
        showPass: !prevState.showPass,
      }));
    else if (type == 2)
      this.setState((prevState) => ({
        showCPass: !prevState.showCPass,
      }));
  }
  handleChange_chld(i, e) {
    let child_data = this.state.child_data;
    child_data[i][e.target.name] = e.target.value;
    this.setState({ child_data });
  }
  removeFormFields(i) {
    let child_data = this.state.child_data;
    child_data.splice(i, 1);
    this.setState({ child_data });
  }
  addFormFields() {
    let child_data = JSON.stringify(this.state.child_data);
    var stringify = JSON.parse(child_data);
    var cnt = stringify.length;
    if (cnt > 9) {
      let toastr = '';
      toastr = window.toastr.error; //warning
      toastr('Maximum 10 children are allowed!', {
        positionClass: "toast-bottom-center",
        containerId: "toast-bottom-center"
      });
    } else {
      this.setState(({
        child_data: [...this.state.child_data, { child_name: "", child_age: "", child_scl: "", child_city: "" }]
      }))

    }
  }

  verifyUser = (e) => {

      let ins = '';

      ins = JSON.stringify({
        user_id: this.state.user_id,
        otp:this.state.otp,
        firstname:this.state.first_name,
        lastname:this.state.last_name,
        email:this.state.reg_email 
      });

      otpVerify(ins).then((res) => {

        if (res.status === 1) {
           
          this.setState({otpverified:true})

        }


      });

  }

  handleSubmit = (e) => {
    e.preventDefault();
    let child_data = JSON.stringify(this.state.child_data);
    let toastr = '';
    if (this.validator.allValid()) {

      if(this.state.reg_mobile=='0000000000'){
        let er = "Please provide your valid mobile number" //warning
        toastr = window.toastr.error; //warning
        toastr(er, toastr, {
          positionClass: "toast-bottom-center",
          containerId: "toast-bottom-center"
        });
      }
      else{
      this.setState({
        signupbtn: true
      });
      var regData = JSON.stringify({
        logintype: 1,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        reg_mobile: this.state.reg_mobile,
        reg_email: this.state.reg_email,
        reg_city: this.state.city_selected,
        reg_child_name: child_data,
        reg_child_age: '',
        reg_password: this.state.reg_password,
        reg_confirmpassword: this.state.reg_confirmpassword,
      });

      userSignUp(regData).then((response) => {

        if (response.status == 1) {


          /*
          var myhtml = document.createElement("div");

          myhtml.innerHTML = `<p><b>Please verify your emailsssss</b></p>
          <p>
            Your Activation link  has been sent your to registered email id please 
            click the link to activate your account. </br>(Check your SPAM folder too)
          </p>`;
          swal({
            content: myhtml,
            // html: true,
            // text: "Please verify your email!",
            icon: "success",
            button: "Okay!",
          }).then((value) => {
            window.location.href = '/'
          });
          */
          this.setState({user_id:response.data.user_id, verficationPopup:true});


        } else {
          this.setState({
            signupbtn: false
          });
          let er = response.errors[0].msg
          toastr = window.toastr.error; //warning
          toastr(er, toastr, {
            positionClass: "toast-bottom-center",
            containerId: "toast-bottom-center"
          });
        }
      });
      }
    } else {
      this.validator.showMessages();
    }

  };

    shouldRenderSuggestions = (value, reason) => {
        if (!this.state.showsuggest)
            return value.trim().length > 2;
    };

    getSuggestionValue(suggestion) {
      return suggestion.name;
    }

    renderSuggestion(suggestion) {
      return (
          <span>{suggestion.name}</span>
      );
    } 

  onSuggestionsFetchRequested = ({ value }) => {
      this.getSuggestions(value).then(response => {
          this.setState({
              suggestions: response ? response : this.state.defaultSuggest,
              showsuggest: false,
          });
      })
  };

  onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: []
      });
  };


  onSuggestionSelected(event, { suggestionValue }) {

      this.setState({
        reg_city: suggestionValue
      });

      /* window.location.replace('/search/' + suggestionValue);*/
  }   

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }


  getSearchListFetch = (val) => {
    if (val.trim().length > 2) {
        let data = JSON.stringify({
            keyword: val
        })
        return getSearchCity(data).then(response => response);
    }
  }

  getSuggestions = async (value) => {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    let list = {};
    list = await this.getSearchListFetch(escapedValue);
    var final = [];
    if(list.status){
    if (list.status === 1) {
        final = list.list
        this.setState({
            searchList: list.list
        })
    } else {
        final = [];
    }
    }
    return final;
}  

  onChangeAutoSg = (event, { newValue, method }) => {
    this.setState({
        value: newValue,
        showsuggest: false
    });
  };

  render() {

    const { value, suggestions, data } = this.state;

    const inputPropsCityReg = {
      placeholder: "City",
      value,
      class: 'form-control',
      id: 'reg_city',
      onChange: this.onChangeAutoSg,
      ref: this.wrapperRef,
      onClick: () => {
          this.setState({
              showsuggest: true
          })
      }
    };

    return (
      <React.Fragment>
        <div className=''>
          {getSocialMedia()}

           {this.state.verficationPopup  && this.state.otpverified && 

                      <div class="aftervrying alert alert-success">
                          
                            Thank you for registering with us and your account has been verified successfully. Please click 'Sign In' to login into our BeWise Website.

                      </div>
          }

          {this.state.verficationPopup && !this.state.otpverified && 

            <div>

              <div className='row'>
                <div className='col-md-12 form-group'>
                  <input
                    type='text'
                    name='otp'
                    value={this.state.otp}
                    placeholder="OTP"
                    onChange={this.handleChange}
                    className='form-control'
                  />
                </div>

              </div>

              <div className='row form-group has-feedback mb-4'>
                <div className='col-md-12'>
                  <input
                    type='button'
                    name='submit'
                    onClick={this.verifyUser}
                    value={this.state.verfiying ? 'Please Wait...' : 'Verify'}
                    disabled={this.state.verfiying ? true : false}
                    className='submit btn btn-primary btn-block btn-flat bdr-15 newlogin-btn float-right'
                  />
                </div>
              </div>

            </div>

          }

          {!this.state.verficationPopup && 
          <div>
            <form onSubmit={this.handleSubmit} autoComplete='off' id='signupForm'>
              <div className='row'>
                <div className='col-md-12 form-group has-feedback'>

                  <input
                    type='text'
                    name='reg_email'
                    placeholder='Email Address'
                    value={this.state.reg_email}
                    onChange={this.handleChange}
                    className='form-control'
                  />
                  {this.validator.message('Email ID', this.state.reg_email, 'required|email', { className: 'text-danger' })}
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 form-group has-feedback'>

                  <input
                    type={this.state.showPass ? 'text' : 'password'}
                    name='reg_password'
                    value={this.state.reg_password}
                    placeholder="Password"
                    onChange={this.handleChange}
                    className='form-control'
                  />
                  <span
                    toggle='#password-field'
                    className={this.state.showPass ? 'fa fa-fw fa-eye field-icon' : 'fa fa-fw fa-eye-slash field-icon'}
                    onClick={() => {
                      this.handleShowPassword(1);
                    }}
                  ></span>
                  {this.validator.message('Password', this.state.reg_password, 'required|password_custome', { className: 'text-danger' })}
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 form-group has-feedback'>

                  <input
                    type={this.state.showCPass ? 'text' : 'password'}
                    name='reg_confirmpassword'
                    value={this.state.reg_confirmpassword}
                    placeholder="Confirm Password"
                    onChange={this.handleChange}
                    className='form-control'
                  />
                  <span
                    toggle='#password-field'
                    className={this.state.showCPass ? 'fa fa-fw fa-eye field-icon' : 'fa fa-fw fa-eye-slash field-icon'}
                    onClick={() => {
                      this.handleShowPassword(2);
                    }}
                  ></span>
                  {this.validator.message('Confirm Password', this.state.reg_confirmpassword, 'required|confirmpassword', { className: 'text-danger' })}
                </div>
              </div>
              <div className='row'>
                <div
                  className='col-md-6'
                >
                  <label className='newlogin-email-text'><b>Your Details</b></label>
                </div>
              </div>
              <div className='row'>
                <div
                  className='col-md-6 form-group has-feedback'
                >

                  <input
                    type='text'
                    name='first_name'
                    placeholder='First Name'
                    value={this.state.first_name}
                    onChange={this.handleChange}
                    className='form-control'
                  />
                  {this.validator.message('First Name', this.state.first_name, 'required|alpha', { className: 'text-danger' })}
                </div>
                <div
                  className='col-md-6 form-group has-feedback'
                >

                  <input
                    type='text'
                    name='last_name'
                    placeholder='Last Name'
                    value={this.state.last_name}
                    onChange={this.handleChange}
                    className='form-control'
                  />
                  {this.validator.message('Last Name', this.state.last_name, 'required|alpha', { className: 'text-danger' })}
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6 form-group has-feedback'>

                  <input
                    type='number'
                    placeholder='Mobile Number'
                    name='reg_mobile'
                    value={this.state.reg_mobile}
                    onChange={this.handleChange}
                    className='form-control'
                  />
                  {this.validator.message('Mobile Number', this.state.reg_mobile, 'required|numeric|min:10|max:10', { className: 'text-danger' })}
                </div>
                <div className='col-md-6 form-group has-feedback autofillinputbox'>

                      <Autosuggest
                      suggestions={suggestions}
                      theme={theme}
                      shouldRenderSuggestions={this.shouldRenderSuggestions}
                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                      onSuggestionSelected={this.onSuggestionSelected}
                      getSuggestionValue={this.getSuggestionValue}
                      renderSuggestion={this.renderSuggestion}
                      alwaysRenderSuggestions={this.state.showsuggest}
                      inputProps={inputPropsCityReg} />
                  
                  {this.validator.message('City', this.state.reg_city, 'required', { className: 'text-danger' })}
                </div>
              </div>
              <div className='row'>
                <div
                  className='col-md-6'
                >
                  <label className='newlogin-email-text'><b>Children Details</b></label>
                </div>
              </div>
              {this.state.child_data.map((item, i) => {
                return (
                  <React.Fragment>
                    {i !== 0 &&
                      <div className='row'>
                        <div className='col-md-12'>
                          <span className='remove pull-right' onClick={() => this.removeFormFields(i)}><i class="fa fa-trash" aria-hidden="true"></i></span>
                        </div>
                      </div>
                    }
                    <div className='row'>

                      <div className='col-md-6 form-group'>
                        <input type="text" name="child_name" value={item.child_name}
                          placeholder='Child Name' onChange={e => this.handleChange_chld(i, e)}
                          className="form-control" />
                      </div>
                      <div className='col-md-6 form-group'>
                        <input type="text" value={item.child_age}
                          placeholder='Age' onChange={e => this.handleChange_chld(i, e)}
                          name="child_age" className="form-control" />
                        {this.validator.message('Child Age', item.child_age, 'numeric|max:15,num', { className: 'text-danger' })}
                      </div>
                    </div>
                    <div className="row">
                      <div className='col-md-6 form-group'>
                        <input type="text" placeholder='School Name' onChange={e => this.handleChange_chld(i, e)}
                          name="child_scl" value={item.child_scl} className="form-control" />
                      </div>
                      <div className='col-md-6 form-group'>
                        <input type="text"
                          placeholder='City' onChange={e => this.handleChange_chld(i, e)}
                          name="child_city" value={item.child_city} className="form-control" />
                      </div>

                    </div>
                  </React.Fragment>
                )
              })}
              <div className='row mb-3'>
                <div className='col-md-12'>
                  <button className="btn btn-primary btn-sm add pull-right new-signup-add"
                    type="button" onClick={() => this.addFormFields()}><i class="fa fa-plus" aria-hidden="true"></i> Add Child</button>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 form-group has-feedback'>
                  <Recaptcha
                    sitekey={GOOGLE_CAPTCHA_SITE_KEY}
                    render="explicit"
                    badge="inline"
                    onloadCallback={this.recaptchaLoaded}
                    verifyCallback={this.verifyCallback}
                  />
                  {this.validator.message('Google Captcha', this.state.isValidCaptcha, 'accepted', { className: 'text-danger' })}
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 form-group has-feedback mb-0'>
                  <label className="custom-checkboxs" style={{ marginTop: "10px" }}>
                    <label className='n7tr'>
                      <input type="checkbox" className=""
                        name="ind_agree"
                        value="Yes"
                        onChange={this.toggleChange}
                        checked={this.state.agree_data} />
                      <span className='newlogin-terms-text'>&nbsp;&nbsp; I agree to <span style={{ color: "#1492E6" }}>
                        <a href="/termsconditions">
                          Terms of service
                        </a> and &nbsp;
                        <a href="/privacypolicy">
                          Privacy policy
                        </a>
                      </span>
                        <span class="astrick">*</span></span></label>
                  </label>
                  {this.validator.message('Agree', this.state.agree_data, 'accepted', { className: 'text-danger' })}
                </div>
              </div>
              <div className='row form-group has-feedback mb-4'>
                <div className='col-md-12'>
                  <input
                    type='submit'
                    name='submit'
                    value={this.state.signupbtn ? 'Please Wait...' : 'Sign Up'}
                    disabled={this.state.signupbtn ? true : false}
                    className='submit btn btn-primary btn-block btn-flat bdr-15 newlogin-btn float-right'
                  />
                </div>
              </div>
            </form>

          </div>
          }

        </div>

      </React.Fragment >
    );
  }
}
export { Newlogin, Login, Signup };

async function linkedinAuthbackup(token, statecode) {
  var authpost = JSON.stringify({
    grant_type: 'authorization_code',
    code: token,
    client_id: LINKEDIN_CLIENT,
    client_secret: LINKEDIN_SECRET,
    redirect_uri: BASE_URL + '?type=linkedLogin',
  });
  let toastr = '';
  fetch(config.register + 'user/auth/linkedAuth', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: authpost,
  }).then(res => res.json()).then(res => {

    if (res.status === 1) {
      // let profile = { first_name: 'Sabarish', last_name: 'Smitiv', token: '4Ust8OR2La' };
      let profile = res.data;
      let emailid = res.data.emailelement.elements[0]['handle~'].emailAddress;
      var regData = JSON.stringify({
        token: profile.token,
        login_type: 4,//linkedin
        name: profile.first_name,
        last_name: profile.last_name,
        reg_mobile: '',
        email: emailid,
        reg_child_name: '',
        reg_child_age: '',
        reg_password: '',
        reg_confirmpassword: '',

      });
      socialLogin(regData);
    } else {
      toastr = window.toastr.error; //warning
      toastr('', res.message, {
        positionClass: "toast-bottom-center",
        containerId: "toast-bottom-center"
      });
    }
  })
}

async function prepareLoginButton(response) {
  let profile = response.profileObj;
  var regData = JSON.stringify({
    g_token: response.tokenId,
    token: profile.googleId,
    login_type: 2,
    name: profile.givenName,
    last_name: profile.familyName,
    reg_mobile: '',
    email: profile.email,
    reg_child_name: '',
    reg_child_age: '',
    reg_password: '',
    reg_confirmpassword: '',

  });
  socialLogin(regData);
}
async function responseFacebook(response) {
  if (response.accessToken) {
    var regData = JSON.stringify({
      token: response.userID,
      login_type: 3, //fb
      name: response.name,
      last_name: '',
      reg_mobile: '',
      email: response.email,
      reg_child_name: '',
      reg_child_age: '',
      reg_password: '',
      reg_confirmpassword: '',

    });
    socialLogin(regData);

  } else {
    let toastr = window.toastr.error; //warning
    toastr('', 'Something went wrong. try again!...', {
      positionClass: "toast-bottom-center",
      containerId: "toast-bottom-center"
    });
  }
}

async function socialLogin(regData) {
  social_login(regData).then((response) => {
    // console.log('status', response.status)
    let toastr = '';
    if (response.status == 1) {

      let userdata = {
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        user_id: response.data.user_id,
        login_type: response.data.login_type,
      }
      localStorage.setItem("logged_auth", true);
      localStorage.setItem('myData', JSON.stringify(userdata));

      if (response.data.is_new == true) {
        var myhtml = document.createElement("div");
        myhtml.innerHTML = `<p class='text-center'>Request to update your details in 
        your profile page to help us serve you better</p>`;
        swal({
          title: "Success",
          content: myhtml,
          icon: "success",
          buttons: true,
        }).then((willDelete) => {
          if (willDelete) {
            window.location.href = '/edit_profile'
          } else {
            window.location.href = '/'
          }
        });
      } else {
        toastr = window.toastr.success;
        toastr('', 'Login success!..', {
          positionClass: "toast-bottom-center",
          containerId: "toast-bottom-center"
        });
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      }


    } else {
      toastr = window.toastr.error; //warning
      toastr('', response.message, {
        positionClass: "toast-bottom-center",
        containerId: "toast-bottom-center"
      });
    }
  });
}