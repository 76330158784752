import React, { Component } from 'react';
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import { CommunityCenter_Banner } from '../Home/Banner';
import FetchCall from '../../API/FetchCall';
import { getCommunitySupportCategoryIdBySlug, getAllPresSchools, adviceSecondFilter, TrendingResults, catgoryList } from '../../API/FetchCall';
import { Link } from 'react-router-dom';
import { TopTrending } from './../Banner/topTrending';
import Rating from 'react-star-rating-component';
import ReactTooltip from "react-tooltip";
import {
  BASE_URL, PAGI_PERPAGE, MAXDIS_FIL, MAXFEE_FIL, IMG_URL, SCREEN_SIZE,
  FIL_DIS, MAXDIS_FILP, FIL_FEEP, MAXFEE_FILPlay1, MAXFEE_FILP,
  CENTERLAT, CENTERLNG, RATING_GOOGLE, PAGINATION_LBL, DEFAULT_DIS, DEFAULT_FEE, DEFAULT_FEE_OTHER
} from '../../constant';
import { AdsOne, AdsTwo, AdsHorizandal, AdsVertical, AdsDynamicMobileMiddle} from '../../components/others/ads';
import { CommunityRight } from '../../components/others/ads_vertical_landing';
import JwPagination from 'jw-react-pagination';
import $ from 'jquery';
import { Multiselect } from 'multiselect-react-dropdown';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Geocode from "react-geocode";

import { Helmet } from 'react-helmet';
import meta_function from '../../meta_data';

import { verticalTotalCount, verticalListing } from '../../API/FetchCall';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import actionicon1 from "./../../assets/icons/v2/compare.png";
import actionicon3 from "./../../assets/icons/v2/wishlist.png";
import { addToWishList, addToCompareList } from '../../common.js';

Geocode.setApiKey("AIzaSyA9MtbfG_zlo64B2lqNMvigTBuXHkagKcE");
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.enableDebug();

const selectedDataList = [];
class CommunitySupports extends Component {
  constructor(props) {
    super(props);
    let centerLocation = 'MG road Bangalore';
    if (this.props.match.params.city == 'chennai') {
      centerLocation = 'Nungambakkam Chennai';
    }
    let meta = meta_function.get_meta_data('com-sup', this.props.match.params.subcategory, this.props.match.params.city);
    this.state = {
      meta_title: meta.meta_title,
      meta_desc: meta.meta_desc,
      pageindex: 1,
      totalsplitpages: 0,
      totalrecords: 0,
      pgnumber: 0,
      centerLocation: centerLocation,
      cate_desc: '',
      similarList: [],
      pageOfItems: [],
      affilation: [],
      review: 0,
      reviews: 0,
      review_change: false,
      review_social: 0,
      showElements: false,
      distance_option: false,
      facilities_option: false,
      sortby_option: true,
      review_option: false,
      reviews_option: false,
      schoolsList: [],
      filteredList: [],
      distList: [],
      facilitesList: [],
      feeList: [],
      fees_option: false,
      error: false,
      filters: { facilities: [], review: '' },
      distanceList: [],
      pages: [1],
      lat: localStorage.getItem("latitude") ? localStorage.getItem("latitude") : CENTERLAT,
      lng: localStorage.getItem("longitude") ? localStorage.getItem("longitude") : CENTERLNG,
      page_number: 1,
      currentpage: 1,
      distance: DEFAULT_DIS,
      distance_change: false,
      annual_fee: DEFAULT_FEE_OTHER,
      annual_fee_change: false,
      disMax: MAXDIS_FIL,
      priceMax: MAXFEE_FIL,
      reviewList: [],
      catelist: [],
      active_cate: localStorage.getItem("cp_menu") ? localStorage.getItem("cp_menu") : 'Counselor',
      //active_cate: localStorage.getItem("sub_type") ? localStorage.getItem("sub_type") : 'Counselor',
      sub_categories: localStorage.getItem("cp_menu_id") ? localStorage.getItem("cp_menu_id") : '122',
      //sub_categories: localStorage.getItem("sub_typeid") ? localStorage.getItem("sub_typeid") : '122',
      dis_arrow: 'fa-angle-down',
      fac_arrow: 'fa-angle-down',
      fee_arrow: 'fa-angle-down',
      review_arrow: 'fa-angle-down',
      reviews_arrow: 'fa-angle-down',
      learn_arrow: 'fa-angle-down',
      class_arrow: 'fa-angle-down',
      service_arrow: 'fa-angle-down',
      ptype_arrow: 'fa-angle-down',
      patype_check: [],
      schooltypeList: [],
      partner_type: [],
      selectedLType: [],
      service_option: false,
      filter_toggle: false,
      totalPages: 0,
      totalResults: 0,
      isloading: true,
      filter_sort: false,
      sortby: '',
      schoolSEOContent:'',
      perpage: PAGI_PERPAGE,
      user_id: 0,
      logged_auth: localStorage.getItem("logged_auth") ? localStorage.getItem("logged_auth") : false,
      current_location:(localStorage.getItem("current_location"))?localStorage.getItem("current_location"):this.props.match.params.city,
    };
    this.filterToogle = this.filterToogle.bind(this);
    this.getfacilities(); this.getschool_type(); this.list_system_nannies(); this.list_system();
    this.handleDistanceChange = this.handleDistanceChange.bind(this);

    this.handleRatingChange = this.handleRatingChange.bind(this);
    this.onStarClickSocial = this.onStarClickSocial.bind(this);
  }

  setSeoContent(){

        let html=`<p>Choose Best Child Counseling Center in `+this.props.match.params.city+` | Top Child Counseling Center in `+this.props.match.params.city+` | Select the Best Child Counseling Center Near Me in `+this.props.match.params.city+` | Top Child Counseling Center Near Me | Best Child Counseling Center Near Me&nbsp;</p>
                  <p>Signup now for the Best Child Counseling Center Near Me&nbsp;</p>
                  <p>Are you searching for the Best Child Counseling Center in `+this.props.match.params.city+`?&nbsp;</p>
                  <p>The use of counseling sessions may help children cope and perform better after experiencing trauma or losing a loved one. Counseling or therapy can help children who have chronic emotional and psychological problems. In child counseling, the goal is to determine the problem and assess the child&#39;s mind. Counseling for children covers mental health issues such as anxiety and depression. For parents looking for &quot;<a href="https://www.bewise.in/`+this.props.match.params.city+`/community-support/counselor" rel="noreferrer noopener" target="_blank">best counseling center near me</a>&quot; for their children, our team at Bewise compiled a list of some of the best counseling centers in `+this.props.match.params.city+`.&nbsp;</p>`;

        this.setState({schoolSEOContent:html});


  }

  updateStyleOfFee() {
    const allRanges = document.querySelectorAll(".range-wrap");

    allRanges.forEach(wrap => {
      const range = wrap.querySelector(".slider");
      const bubble = wrap.querySelector(".bubble");
      range.addEventListener("input", () => {
        setBubble(range, bubble);
      });
      setBubble(range, bubble);
    });

    function setBubble(range, bubble) {
      const val = range.value;
      console.log('setBubble', val)
      const min = range.min ? range.min : 0;
      const max = range.max ? range.max : 100;
      const newVal = Number(((val - min) * 100) / (max - min));
      bubble.innerHTML = val;
      // Sorta magic numbers based on size of the native UI thumb
      bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
    }
  }




  partnerType = (e) => {
    const propName = e.target.name;
    const partner_type = [...e.target.selectedOptions].map((item) => item.value);
    this.setState({ [propName]: partner_type });
    this.setState({
      partner_type: partner_type,
    });

    this.handleFilterChange(this.state.distance, this.state.review, this.state.affilation, this.state.classification, selectedDataList, this.state.sub_categories, this.state.sortby, this.state.sort_order, this.state.annual_fee, this.state.review_social, partner_type);
  }
  handleDistanceChange = (e) => {
    let distance = e.target.value;
    this.setState({
      distance: e.target.value,
    });
    this.updateStyleOfFee();
    this.handleFilterChange(distance, this.state.review, this.state.affilation, this.state.classification, selectedDataList, this.state.sub_categories, this.state.sortby, this.state.sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type);
  }



  handleAnnualfeeChange = (e) => {
    let annual_fee = e.target.value;
    this.setState({
      annual_fee: e.target.value,
    });
    this.updateStyleOfFee();
    this.handleFilterChange(this.state.distance, this.state.review, this.state.affilation, this.state.classification, selectedDataList, this.state.sub_categories, this.state.sortby, this.state.sort_order, annual_fee, this.state.review_social, this.state.partner_type);
  }

  handleRatingChange = (e) => {
    let review = e.target.value;
    this.setState({
      review: e.target.value,
    });
    this.updateStyleOfFee();
    this.handleFilterChange(this.state.distance, review, this.state.affilation, this.state.classification, selectedDataList, this.state.sub_categories, this.state.sortby, this.state.sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type);
  }


  onStarClickSocial = (e) => {
    let probName = e.target.name;
    const filterValue = e.target.value;
    this.setState({ [probName]: filterValue });
    this.updateStyleOfFee();
    this.handleFilterChange(this.state.distance, this.state.review, this.state.affilation, this.state.classification, selectedDataList, this.state.sub_categories, this.state.sortby, this.state.sort_order, this.state.annual_fee, filterValue, this.state.partner_type);
  }



  handleLearningCheckbox = (e) => {
    let affilation;
    var checkboxes = document.querySelectorAll('input[name="affilation"]:checked'), values = [];
    Array.prototype.forEach.call(checkboxes, function (el) {
      values.push(el.value);
    });
    affilation = values;
    this.setState({
      affilation: values,
      selectedLType: values
    });
    this.handleFilterChange(this.state.distance, this.state.review, affilation, this.state.classification, selectedDataList, this.state.sub_categories, this.state.sortby, this.state.sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type);
  }
  handlePtypeCheck = (e) => {
    let partner_type;
    var checkboxes = document.querySelectorAll('input[name="partner_type"]:checked'), values = [];
    Array.prototype.forEach.call(checkboxes, function (el) {
      values.push(el.value);
    });
    partner_type = values;
    this.setState({
      partner_type: partner_type,
    });

    this.handleFilterChange(this.state.distance, this.state.review, this.state.affilation, this.state.classification, selectedDataList, this.state.sub_categories, this.state.sortby, this.state.sort_order, this.state.annual_fee, this.state.review_social, partner_type);
  }

  handleClassFeqChange = (e) => {
    let classification;
    var checkboxes = document.querySelectorAll('input[name="classification"]:checked'), values = [];
    Array.prototype.forEach.call(checkboxes, function (el) {
      values.push(el.value);
    });
    classification = values;
    this.setState({
      classification: values,
    });
    this.handleFilterChange(this.state.distance, this.state.review, this.state.affilation, classification, selectedDataList, this.state.sub_categories, this.state.sortby, this.state.sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type);
  }

  onSelect = (selectedList) => {
    let servicedata = [];
    selectedList.map((item, i) => {
      servicedata.push(item.id)
    });
    selectedDataList = servicedata
    console.log('selectedList', selectedDataList)

    this.setState({
      service_data: selectedDataList,
    });
    this.handleFilterChange(this.state.distance, this.state.review, this.state.affilation, this.state.classification, selectedDataList, this.state.sub_categories, this.state.sortby, this.state.sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type);
  }
  onRemove(selectedList, removedItem) {
    let servicedata = [];
    selectedList.map((item, i) => {
      servicedata.push(item.id)
    });
    selectedDataList = servicedata
    console.log('selectedList', selectedDataList)

    this.setState({
      service_data: selectedDataList,
    });
    this.handleFilterChange(this.state.distance, this.state.review, this.state.affilation, this.state.classification, selectedDataList, this.state.sub_categories, this.state.sortby, this.state.sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type);

  }
  handle_sortby_list = (e, val) => {
    let sortby = e ? e.target.value : val;
    let sort_order = 1;

    this.setState({
      sortby: sortby,
      sort_order: 1,
      sortby_option: true
    });
    this.handleFilterChange(this.state.distance, this.state.review, this.state.affilation, this.state.classification, selectedDataList, this.state.sub_categories, sortby, sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type);
  }
  handlePerPage = (e) => {
    let perpage = e.target.value;
    this.setState({ perpage })
    this.handleFilterChange(this.state.distance, this.state.review, this.state.affilation, this.state.classification, selectedDataList, this.state.sub_categories, this.state.sortby, this.state.sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type, '', '', '', '', perpage);
  }
  clearSort = () => {
    let sortby = '';
    let sort_order = '';

    this.setState({
      sortby: sortby,
      sort_order: '',
      sortby_option: true
    });
    this.handleFilterChange(this.state.distance, this.state.review, this.state.affilation, this.state.classification, selectedDataList, this.state.sub_categories, sortby, sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type);
  }

  handle_Sortby = (e) => {

    this.setState((prevState) => ({
      sortby_option: !prevState.sortby_option,
    }));

    this.handle_SortIcon();

  };

  handle_SortIcon() {
    let sort_order = 0;
    if (this.state.sortby_option == true) {
      sort_order = 2;
    } else if (this.state.sortby_option == false) {
      sort_order = 1;
    }
    this.setState({
      sort_order: sort_order
    });
    this.handleFilterChange(this.state.distance, this.state.review, this.state.affilation, this.state.classification, selectedDataList, this.state.sub_categories, this.state.sortby, sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type);

  }
  typBoxChanged = (e) => {
    const val = [...e.target.selectedOptions].map((item) => item.value);
    this.setState({
      selectedLType: val,
      affilation: val,
    });
    this.setState({
      distance_option: false,
      facilities_option: false,
      fees_option: false,
      review_option: false,
      classification_option: false,
      reviews_option: false,
      service_option: false,
      learning_option: false,
      dis_arrow: 'fa-angle-down',
      learn_arrow: 'fa-angle-down',
      fac_arrow: 'fa-angle-down',
      fee_arrow: 'fa-angle-down',
      review_arrow: 'fa-angle-down',
      class_arrow: 'fa-angle-down',
      reviews_arrow: 'fa-angle-down',
      service_arrow: 'fa-angle-down'
    })
    this.updateStyleOfFee();
    this.handleFilterChange(this.state.distance, this.state.review, val, this.state.classification, selectedDataList, this.state.sub_categories, this.state.sortby, this.state.sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type);
  }

  handleFilterChange(distance, review, affilation, classification, service, sub_categories, sortby, sort_order, annual_fee, review_social, partner_type = '', page_number = 1, operators = '', lat = '', lng = '', perpage) {

    this.setState({isloading:true});
    
    let temppgnum = (this.state.pgnumber == 0) ? 0 : this.state.pgnumber;
    perpage = perpage ? perpage : this.state.perpage;
    if (operators == '+') {

      temppgnum = parseInt(temppgnum) + perpage;
      this.setState({
        pgnumber: temppgnum,
        pageindex: this.state.pageindex + 1
      });

    } else if (operators == '-') {

      temppgnum = parseInt(temppgnum) - perpage;
      this.setState({
        pgnumber: temppgnum,
        pageindex: this.state.pageindex - 1
      });
    }


    let filterData = JSON.stringify({
      current_latitude: lat ? lat : this.state.lat,
      current_longitude: lng ? lng : this.state.lng,
      distance: distance,
      review: review,
      affilation: sub_categories == 122 ? affilation : [],
      classification: classification,
      service: service,
      sub_categories: sub_categories,
      sortby: sortby,
      sort_order: sort_order,
      annual_fee: annual_fee,
      review_social: review_social,
      partner_type: partner_type,
      page_number: page_number ? page_number : this.state.page_number,
      offset: parseInt(temppgnum),
      sub_category_keyword: this.state.active_cate,
      perpage: perpage,
      vertical: 'communitysupport',
      location: this.props.match.params.city
    });

    verticalTotalCount(filterData).then((response) => {
      this.setState({
        totalrecords: response.data
      });

      let divided = Math.ceil(response.data / perpage);

      this.setState({
        totalsplitpages: divided
      });
    });

    verticalListing(filterData).then((response) => {
      if (response.status == 1) {
        const list = response;
        this.setPage(list);
      } else if (response.status == 0) {
        this.setState({
          totalPages: 0,
          isloading: false
        });
      }
    });

  }
  setPage(list, page_number = '') {
    let data = list.data;
    let total_pages = list.total_pages;
    this.setState({
      filteredList: data,
      pageOfItems: data,
      totalPages: total_pages,
      currentpage: page_number != '' ? page_number : 1,
      page_number: page_number != '' ? page_number : 1,
      totalResults: list.length,
      isloading: false
    });
  }
  componentDidUpdate() {
    // let current = window.jQuery(".page-item.page-number.active").find('a').html();
    // console.log('current', current)
    // if ((current) && this.state.currentpage != current) {
    //   this.setState({
    //     currentpage: current
    //   })
    // }
  }
  componentDidUpdate() {
    //window.jQuery('.selectpicker').selectpicker('refresh');
  }

  componentDidMount() {

    localStorage.setItem('cp_menu', '');
    localStorage.setItem('cp_menu_id', '');

    this.setState({
      city: this.props.match.params.city,
      subcategory: this.props.match.params.subcategory
    });

    //this.renderSwitch(this.state.sub_categories);
    this.getPreSchoolList();
    this.setSeoContent();

    /* SEO */
    var var_metatitle = "BeWise"; var var_metadesc = '';
    if (this.props.match.params.subcategory == 'counselor') {
      var_metatitle = "Child Wellbeing/Counselors/Psychologist/Therapist Bengaluru | BeWise";
      var_metadesc = 'Find relevant information and make decisions on child counselors, psychologists, therapists. Explore options for child care and wellbeing at BeWise, a fun and learning experience to help your child learn and grow';
    }

    if (this.props.match.params.subcategory == 'competitions') {
      var_metatitle = "Contests, Competitions, Quizzes For Your Child Bengaluru | BeWise";
      var_metadesc = 'Find relevant information and make decisions on current and upcoming contests, competitions, quizzes for kids across age groups. Explore options for online and offline, fees at BeWise, a fun and learning experience to help your child learn and grow';
    }

    if (this.props.match.params.subcategory == 'articles-blogs') {
      var_metatitle = "Expert Opinions, Blogs, Child Specialist Bengaluru | BeWise";
      var_metadesc = 'Find expert opinions, insights and blogs on all topics related to children and parents, at BeWise, a fun and learning experience to help your child learn and grow';
    }

    if (this.props.match.params.subcategory == 'home-activities') {
      var_metatitle = "Home Based Activities & Fun Learning Resources Bengaluru | BeWise";
      var_metadesc = 'Home based learning activities, hobbies and fun. Find these resources at BeWise, a fun and learning experience to help your child learn and grow';
    }


    this.setState({ metatitle: var_metatitle, metadesc: var_metadesc });



    this.getPreSchoolCateList();
    console.log('Latitude')
    var This = this;
    navigator.geolocation.getCurrentPosition(function (position) {
      This.get_current_loaction(position.coords.latitude, position.coords.longitude);
    },
      function (error) {
        console.error("Error Code = ", error);
      });
    this.updateStyleOfFee();
    this.SResult();
    let data_auth = (localStorage.getItem("myData"));
    let userid;
    if (data_auth) {
      let auth_data = JSON.parse(data_auth)
      this.setState({
        user_id: auth_data.user_id,
      });
    }
  }
  async getfacilities() {
    let response = await FetchCall.getSchoolListDatas('facilities');
    console.log(response);
    if (response) {
      this.setState({ facilitesList: response.results });
    }
  }

  async getschool_type() {
    let response = await FetchCall.getSchoolListDatas('tution_type');
    console.log(response);
    if (response) {
      this.setState({ schooltypeList: response.results });
    }
  }

  async list_system_nannies() {
    let response = await FetchCall.getNanniesDatas('nannies');
    console.log(response);
    if (response.status == 1) {
      this.setState({ classificationList: response.time });
    }
  }
  async list_system() {
    let response = await FetchCall.getSchoolListDatas('services');
    console.log(response);
    if (response) {

      let serive_arr = [];

      response.results.map((item, i) => {
        serive_arr.push({
          "id": item.id,
          "name": item.service,
        })
      });

      this.setState({
        classificationList: response.results,
        options: serive_arr,
      });

    }
  }
  SResult = async () => {

    let reviewData = JSON.stringify({
      module: 2,
    });


    TrendingResults(reviewData).then((response) => {
      if (response.status == 1) {
        console.log('reviewData', response.results)
        this.setState({
          similarList: response.results,
        })
      }
    });
  }


  handle_Distance = (e) => {
    if (this.state.distance_option === false) {
      this.setState({
        dis_arrow: 'fa-angle-up'
      });
    } else {
      this.setState({
        dis_arrow: 'fa-angle-down'
      });
    }
    this.setState((prevState) => ({
      distance_option: !prevState.distance_option,
    }));
    this.setState({
      facilities_option: false,
      fees_option: false,
      review_option: false,
      reviews_option: false,
      classification_option: false,
      learning_option: false,
      service_option: false,
      fac_arrow: 'fa-angle-down',
      fee_arrow: 'fa-angle-down',
      review_arrow: 'fa-angle-down',
      class_arrow: 'fa-angle-down',
      reviews_arrow: 'fa-angle-down',
      learn_arrow: 'fa-angle-down',
      service_arrow: 'fa-angle-down'
    })
    this.updateStyleOfFee();

  };


  handle_Facilities = (e) => {
    if (this.state.facilities_option === false) {
      this.setState({
        fac_arrow: 'fa-angle-up'
      });
    } else {
      this.setState({
        fac_arrow: 'fa-angle-down'
      });
    }
    this.setState((prevState) => ({
      facilities_option: !prevState.facilities_option,
    }));
    this.setState({
      distance_option: false,
      fees_option: false,
      review_option: false,
      reviews_option: false,
      classification_option: false,
      learning_option: false,
      service_option: false,
      dis_arrow: 'fa-angle-down',
      fee_arrow: 'fa-angle-down',
      review_arrow: 'fa-angle-down',
      class_arrow: 'fa-angle-down',
      reviews_arrow: 'fa-angle-down',
      learn_arrow: 'fa-angle-down',
      service_arrow: 'fa-angle-down'
    })
    this.updateStyleOfFee();
  };
  handle_Fees = (e) => {
    if (this.state.fees_option === false) {
      this.setState({
        fee_arrow: 'fa-angle-up'
      });
    } else {
      this.setState({
        fee_arrow: 'fa-angle-down'
      });
    }
    this.setState((prevState) => ({
      fees_option: !prevState.fees_option,
    }));
    this.setState({
      distance_option: false,
      facilities_option: false,
      review_option: false,
      reviews_option: false,
      classification_option: false,
      learning_option: false,
      service_option: false,
      dis_arrow: 'fa-angle-down',
      review_arrow: 'fa-angle-down',
      class_arrow: 'fa-angle-down',
      reviews_arrow: 'fa-angle-down',
      learn_arrow: 'fa-angle-down',
      service_arrow: 'fa-angle-down'
    })
    this.updateStyleOfFee();
  };

  handle_Review = (e) => {
    if (this.state.review_option === false) {
      this.setState({
        review_arrow: 'fa-angle-up'
      });
    } else {
      this.setState({
        review_arrow: 'fa-angle-down'
      });
    }
    this.setState((prevState) => ({
      review_option: !prevState.review_option,
    }));
    this.setState({
      distance_option: false,
      facilities_option: false,
      fees_option: false,
      reviews_option: false,
      classification_option: false,
      learning_option: false,
      service_option: false,
      dis_arrow: 'fa-angle-down',
      fac_arrow: 'fa-angle-down',
      fee_arrow: 'fa-angle-down',
      class_arrow: 'fa-angle-down',
      reviews_arrow: 'fa-angle-down',
      learn_arrow: 'fa-angle-down',
      service_arrow: 'fa-angle-down'
    })
    this.updateStyleOfFee();
  };

  handle_Classification = (e) => {
    if (this.state.classification_option === false) {
      this.setState({
        class_arrow: 'fa-angle-up'
      });
    } else {
      this.setState({
        class_arrow: 'fa-angle-down'
      });
    }
    this.setState((prevState) => ({
      classification_option: !prevState.classification_option,
    }));
    this.setState({
      distance_option: false,
      facilities_option: false,
      fees_option: false,
      review_option: false,
      reviews_option: false,
      learning_option: false,
      service_option: false,
      dis_arrow: 'fa-angle-down',
      fac_arrow: 'fa-angle-down',
      fee_arrow: 'fa-angle-down',
      review_arrow: 'fa-angle-down',
      reviews_arrow: 'fa-angle-down',
      learn_arrow: 'fa-angle-down',
      service_arrow: 'fa-angle-down'
    })
    this.updateStyleOfFee();
  };

  handle_Reviews = (e) => {
    if (this.state.reviews_option === false) {
      this.setState({
        reviews_arrow: 'fa-angle-up'
      });
    } else {
      this.setState({
        reviews_arrow: 'fa-angle-down'
      });
    }
    this.setState((prevState) => ({
      reviews_option: !prevState.reviews_option,
    }));
    this.setState({
      distance_option: false,
      facilities_option: false,
      fees_option: false,
      review_option: false,
      classification_option: false,
      learning_option: false,
      service_option: false,
      dis_arrow: 'fa-angle-down',
      fac_arrow: 'fa-angle-down',
      fee_arrow: 'fa-angle-down',
      review_arrow: 'fa-angle-down',
      class_arrow: 'fa-angle-down',
      learn_arrow: 'fa-angle-down',
      service_arrow: 'fa-angle-down'
    })
    this.updateStyleOfFee();
  };

  handle_learning = (e) => {
    if (this.state.learning_option === false) {
      this.setState({
        learn_arrow: 'fa-angle-up'
      });
    } else {
      this.setState({
        learn_arrow: 'fa-angle-down'
      });
    }
    this.setState((prevState) => ({
      learning_option: !prevState.learning_option,
    }));
    this.setState({
      distance_option: false,
      facilities_option: false,
      fees_option: false,
      review_option: false,
      classification_option: false,
      reviews_option: false,
      service_option: false,
      dis_arrow: 'fa-angle-down',
      fac_arrow: 'fa-angle-down',
      fee_arrow: 'fa-angle-down',
      review_arrow: 'fa-angle-down',
      class_arrow: 'fa-angle-down',
      reviews_arrow: 'fa-angle-down',
      service_arrow: 'fa-angle-down'
    })
    this.updateStyleOfFee();
  };
  handle_ptype = (e) => {
    if (this.state.ptype_option === false) {
      this.setState({
        ptype_arrow: 'fa-angle-up'
      });
    } else {
      this.setState({
        ptype_arrow: 'fa-angle-down'
      });
    }
    this.setState((prevState) => ({
      ptype_option: !prevState.ptype_option,
    }));
    this.setState({
      distance_option: false,
      facilities_option: false,
      learning_option: false,
      fees_option: false,
      review_option: false,
      classification_option: false,
      reviews_option: false,
      service_option: false,
      dis_arrow: 'fa-angle-down',
      learn_arrow: 'fa-angle-down',
      fac_arrow: 'fa-angle-down',
      fee_arrow: 'fa-angle-down',
      review_arrow: 'fa-angle-down',
      class_arrow: 'fa-angle-down',
      reviews_arrow: 'fa-angle-down',
      service_arrow: 'fa-angle-down'
    })
    this.updateStyleOfFee();
  };
  handle_Service = (e) => {
    if (this.state.service_option === false) {
      this.setState({
        service_arrow: 'fa-angle-up'
      });
    } else {
      this.setState({
        service_arrow: 'fa-angle-down'
      });
    }
    this.setState((prevState) => ({
      service_option: !prevState.service_option,
    }));
    this.setState({
      distance_option: false,
      facilities_option: false,
      fees_option: false,
      review_option: false,
      classification_option: false,
      reviews_option: false,
      learning_option: false,
      dis_arrow: 'fa-angle-down',
      fac_arrow: 'fa-angle-down',
      fee_arrow: 'fa-angle-down',
      review_arrow: 'fa-angle-down',
      class_arrow: 'fa-angle-down',
      reviews_arrow: 'fa-angle-down',
      learn_arrow: 'fa-angle-down',
    })
    this.updateStyleOfFee();
  };

  handle_clearFilter = (e) => {
    window.location.reload();
  }



  onStarClick = (e) => {
    let probName = e.target.value;
    let reviewData;
    if (this.state.annual_fee_change != false && this.state.feeList != '') {
      reviewData = this.state.feeList.filter(o => o.rating >= probName);
    } else if (this.state.annual_fee_change == false && this.state.feeList == '' && this.state.distance_change != false && this.state.distList != '') {
      reviewData = this.state.distList.filter(o => o.rating >= probName);
    } else if (this.state.annual_fee_change == false && this.state.distance_change == false) {
      reviewData = this.state.schoolsList.filter(o => o.rating >= probName);
    } else {
      reviewData = [];
    }

    this.setState({ filteredList: reviewData, review: e.target.value, reviewList: reviewData, review_change: true });
    this.updateStyleOfFee();
  }



  handleplaceChange = address => {
    this.setState({ address, pin_code: '', state: '', location: '' });
    if (!address)
      this.handleFilterChange(this.state.distance, this.state.review,
        this.state.affilation, this.state.classification,
        selectedDataList, this.state.sub_categories, this.state.sortby,
        this.state.sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type
        , this.state.page_number, CENTERLAT, CENTERLNG);

  };

  handleplaceSelect = address => {
    this.setState({ address: address });
    // this.Geocode_location();
    Geocode.fromAddress(address).then(
      response => {

        var geolen = response.results[0].address_components.length;
        var pincode = '';
        var state = '';
        var city = '';
        for (let i = 0; i < geolen; i++) {

          if (response.results[0].address_components[i].types[0] == "postal_code") {
            pincode = response.results[0].address_components[i].short_name;
          }
          if (response.results[0].address_components[i].types[0] == "administrative_area_level_1") {
            state = response.results[0].address_components[i].long_name;
          }
          if (response.results[0].address_components[i].types[0] == "administrative_area_level_2") {
            city = response.results[0].address_components[i].long_name;
          }

        }

        this.setState({ lat: response.results[0].geometry.location.lat, lng: response.results[0].geometry.location.lng });
        this.handleFilterChange(this.state.distance, this.state.review,
          this.state.affilation, this.state.classification,
          selectedDataList, this.state.sub_categories, this.state.sortby,
          this.state.sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type
          , this.state.page_number, response.results[0].geometry.location.lat, response.results[0].geometry.location.lng);
      },
      error => {
        console.error(error);
      }
    );
  };

  getPreSchoolCateList = async () => {
    let catData = JSON.stringify({
      id: 121,
    });
    console.log('**list', catData)
    catgoryList(catData).then((response) => {
      if (response.status == 1) {
        const list = response.data;
        // console.log('**list',list)
        this.setState({
          catelist: list,
        });
      } else {

      }
    });
  };

  getPreSchoolList = async () => {


    let SlugName = JSON.stringify({
      category_slug: this.props.match.params.subcategory
    });

    getCommunitySupportCategoryIdBySlug(SlugName).then((response) => {
      if (response.status == 1) {
        this.setState({
          sub_categories: response.subcategory_id,
          active_cate: response.category_name,
          cate_desc: response.descrp,
        });

        let filterData = JSON.stringify({
          current_latitude: this.state.lat,
          current_longitude: this.state.lng,
          distance: this.state.distance,
          annual_fee: this.state.annual_fee,
          review: 0,
          facilities: this.state.facilities,
          classification: this.state.classification,
          sub_categories: response.subcategory_id,
          review_social: 0,
          page_number: this.state.page_number,
          offset: 0,
          sub_category_keyword: this.state.active_cate,
          perpage: this.state.perpage,
          vertical: 'communitysupport',
          location: this.props.match.params.city

        });

        verticalTotalCount(filterData).then((response) => {
          this.setState({
            totalrecords: response.data
          });

          let divided = Math.ceil(response.data / this.state.perpage);

          this.setState({
            totalsplitpages: divided
          });
        });

        verticalListing(filterData).then((response) => {
          if (response.status == 1) {
            const list = response;
            this.setPage(list);
          } else if (response.status == 0) {
            this.setState({
              totalPages: 0,
              isloading: false
            });
          }
        });

      }
    });

  };

  get_current_loaction(latitude, longitude) {
    this.setState({
      current_latitude: latitude,
      current_longitude: longitude,
    });
  }

  showElement = () => {
    this.setState({
      showElements: !this.state.showElements,
    })
  }
  renderSwitch(param) {
    console.log('paramparam', param);
    let category = '';
    switch (param.toString()) {
      case '122':
        category = 'counselorInfo';
        break;
      case '123':
        category = 'nanniesInfo';
        break;
      case '148':
        category = 'nanniesInfo';
        break;
      case '124':
        category = 'blogsInfo';
        break;
      case '125':
        category = 'homeactiviesInfo';
        break;
      default:
        category = 'counselorInfo';
        break;
    }
    // this.setState({
    //   categoryLink: category,
    // });
    return category;
  }


  onChangePrevPage = (page_number) => {
    this.setState({
      pgnumber: page_number,
      currentpage: page_number
    })
    this.handleFilterChange(this.state.distance, this.state.review, this.state.affilation, this.state.classification, selectedDataList, this.state.sub_categories, this.state.sortby, this.state.sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type, page_number, '-');
    window.scrollTo(0, 0);
  }

  onChangeNextPage = (page_number) => {
    this.setState({
      pgnumber: page_number,
      currentpage: page_number
    })
    this.handleFilterChange(this.state.distance, this.state.review, this.state.affilation, this.state.classification, selectedDataList, this.state.sub_categories, this.state.sortby, this.state.sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type, page_number, '+');
    window.scrollTo(0, 0);
  }


  onChangePage = (page_number) => {
    this.setState({
      page_number: page_number,
      currentpage: page_number
    })
    this.handleFilterChange(this.state.distance, this.state.review, this.state.affilation, this.state.classification, selectedDataList, this.state.sub_categories, this.state.sortby, this.state.sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type, page_number);
  }

  homeLink = () => {
    window.location.replace('/');
  }
  subLink = () => {
    window.location.replace('/communitylinks');
  }

  breadLinkHome = () => {
    window.location.replace('/' + this.state.city + '/' + 'community-support' + '/' + 'counselor');
  }

  sub_cate() {
    localStorage.setItem('sub_type', this.state.active_cate);
    localStorage.setItem('cp_menu', this.state.active_cate);
    localStorage.setItem('cp_menu_id', this.state.sub_categories);
    localStorage.setItem('primary_search_path', window.location.href);
  }

  handleCategory(name, id) {
    this.setState({
      active_cate: name,
      sub_categories: id,
    });
    this.handleFilterChange(this.state.distance, this.state.review, this.state.facilities, this.state.classification, selectedDataList, id, this.state.sortby, this.state.sort_order, this.state.annual_fee, this.state.review_social, this.state.partner_type, 1);
  }

  handleSubCategory = (subcategoryname) => {
    window.location.replace('/' + this.state.city + '/community-support/' + subcategoryname);
  }
  filterToogle = () => {
    this.setState({
      filter_toggle: !this.state.filter_toggle,
      filter_sort: false
    });
  }
  sortToogle = () => {
    this.setState({
      filter_sort: !this.state.filter_sort,
      filter_toggle: false
    });
  }

  render() {

    const { schoolsList } = this.state;
    let topTrending = {
      category_id: 121,
      city: this.props.match.params.city,
      subcategory: this.state.sub_categories,
      subcategory_term: this.props.match.params.subcategory,
      category_name: 'community-support',
      sub_category: 0
    }
    const menuData = {
      category: 'Community Support',
      category_link: `/${this.state.city}/community-support/counselor`,
      subcategory_one: this.state.active_cate,
      subcategory_one_link: '',
      subcategory_one_desc: this.state.cate_desc,
      active_main: 'community-support',
      active_menu: this.props.match.params.subcategory
    }
    console.log('this.props.match.params.subcategory', this.props.match.params.subcategory)
    let filter_opt = false;
    if (this.props.match.params.subcategory == 'articles-blogs')
      filter_opt = true;
    return (
      <React.Fragment>
        {this.state.isloading === true &&
          (
            <React.Fragment>
              <div class="overlay-custom"></div>
              <div class='spinner' role='status'>
                <span class='sr-only'>Loading...</span>
              </div>
            </React.Fragment>
          )}
        

        <Header menu_data={menuData} filter_opt={filter_opt} login={this.state.openModalLogin}
          onMobileFilterCall={this.filterToogle} onMobileSortCall={this.sortToogle} />
        <span id='Popover-mobile-filter'></span>
        <span id='Popover-mobile-sort'></span>
        <Popover className='filter-pop-over' hideArrow={true}
          placement="bottom" isOpen={this.state.filter_toggle} target="Popover-mobile-filter">
          <PopoverHeader>Sharpen your search <span class='popupclose' onClick={this.filterToogle}>close</span></PopoverHeader>
          <PopoverBody>
            {this.filterFunction(1)}
          </PopoverBody>
        </Popover>
        <Popover className='filter-pop-over' hideArrow={true}
          placement="bottom" isOpen={this.state.filter_sort} target="Popover-mobile-sort">
          <PopoverHeader>
            Sort
            <span class='popupclose' onClick={this.sortToogle}>close</span>
          </PopoverHeader>
          <PopoverBody>
            <div className='container'>
              {this.state.sortby && (
                <div className='row scrollmenu new-sort-option'>
                  <div className='col-12'>
                    <a
                      className={this.state.sortby_option ? 'filter-menu active' : 'filter-menu'}
                      href='javascript:;' onClick={this.handle_Sortby} >
                      Ascending
                    </a>
                    &nbsp;|&nbsp;
                    <a className={!this.state.sortby_option ? 'filter-menu active' : 'filter-menu'}
                      href='javascript:;' onClick={this.handle_Sortby}>
                      Descending
                    </a>
                  </div>
                </div>
              )}
              <div className='row'>
                <div className='col-12'>
                  <ul className='pop-sort'>
                    <li
                      onClick={() => this.handle_sortby_list(null, '')}
                      className={this.state.sortby == '' && 'active'}>Default Sort </li>
                    <li
                      onClick={() => this.handle_sortby_list(null, 1)}
                      className={this.state.sortby == 1 && 'active'}>Name </li>
                    <li
                      onClick={() => this.handle_sortby_list(null, 2)}
                      className={this.state.sortby == 2 && 'active'}>Location </li>
                    <li
                      onClick={() => this.handle_sortby_list(null, 3)}
                      className={this.state.sortby == 3 && 'active'}>Rating</li>
                    <li
                      onClick={() => this.handle_sortby_list(null, 4)}
                      className={this.state.sortby == 4 && 'active'}>Fees</li>
                  </ul>
                </div>
              </div>
            </div>
          </PopoverBody>
        </Popover>
        <section class="secound-landing features-area nav nav-tabs new-landing-page" id="nav-tab" role="tablist">
          <div className='container'>
            <div class="row" style={{ padding: 10 }}>
              <div className="mt-3 col-lg-3 col-md-4 pl-md-0 d-none d-md-block">
                <div className='laning-stick'>
                  <div className="left-side-box">
                    <div className="left-head mob-col-wrap d-none d-md-block">
                      Community Support
                      <button className="btn mob-collapse-btn pr-0 d-block d-md-none">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div className="left-side-body">
                      <ul>
                        {this.state.catelist.map((item, i) => {
                          let active;
                          if (this.state.active_cate == item.category_name) {
                            active = 'active';
                          } else {
                            active = '';
                          }
                          return (
                            <li onClick={() => this.handleSubCategory(item.slug_term)} className={active}>
                              {item.category_name} &nbsp;
                              {item.descrp && (
                                <span className='d-none d-md-block'>
                                  <i class="fa fa-info-circle" data-tip data-for={`catetipM${i}`}></i>
                                  <ReactTooltip id={`catetipM${i}`} place="bottom" effect="solid" className='custome-info-data'>
                                    <p style={{ textAlign: "left" }}>{item.descrp}</p>
                                  </ReactTooltip>
                                </span>)}
                            </li>
                          );
                        })}

                      </ul>
                    </div>
                  </div>
                  <div className="left-side-box">
                    <div className="left-head mob-col-wrap d-none d-md-block">
                      Sharpen your Search &nbsp; <img src="/assets/icons/animatedarrow.gif" class="arrowanimated" />
                      <button className="btn mob-collapse-btn pr-0 d-block d-md-none">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                    </div>
                    {this.filterFunction(2)}
                  </div>
                  <div className="d-none d-md-block">
                    <TopTrending param={topTrending} />
                  </div>
                </div>
              </div>
              <div className="mt-3 col-lg-8 col-md-8">
                <div className="col-12 px-0 d-none d-md-block">
                  <div className='row landing-tile-breadcurmb'>
                    <div className='col-6'>
                      <span className='mb4c'>
                        <b style={{ 'cursor': 'pointer' }} onClick={() => this.homeLink()}>Home&nbsp;<i class="fa fa-angle-right"></i>&nbsp;</b>
                        <b style={{ 'cursor': 'pointer' }} onClick={() => this.breadLinkHome()}>Community Support&nbsp;<i class="fa fa-angle-right"></i>&nbsp;</b>
                        <b><span style={{ textTransform: 'capitalize' }}>{this.state.active_cate}</span></b>
                      </span>
                    </div>
                    <div className='col-2'>

                    </div>
                    <div className='col-4'>
                      <div className='row align-items-center'>
                        <div className={this.state.sortby ? 'col-md-6' : 'col-md-6 offset-md-1'}>
                          <select className='form-control special landing-sort-drop selectpicker' name="sortby" value={this.state.sortby} onChange={this.handle_sortby_list}>
                            <option value="">Default Sort</option>
                            <option value="1">Name </option>
                            <option value="2">Location </option>
                            <option value="3">Rating</option>
                            <option value="4">Fee</option>
                          </select>
                        </div>
                        {this.state.sortby &&
                          (
                            <div className='col-md-1 p-0'>
                              <span className="tile-sort-icon" onClick={this.handle_Sortby}>
                                {(this.state.sortby_option == true) ?
                                  (<i class="fas fa-sort-amount-up" aria-hidden="true"></i>) :
                                  (<i class="fas fa-sort-amount-down" aria-hidden="true"></i>
                                  )}
                              </span>
                            </div>
                          )
                        }
                        <div className={this.state.sortby ? 'col-md-5' : 'col-md-5'}>
                          <select className='form-control selectpicker special landing-sort-drop'
                            name="perpage" value={this.state.perpage} onChange={this.handlePerPage}>
                            <option value="15">15</option>
                            <option value="30">30 </option>
                            <option value="50">50</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                <div class="row pageTitleH1" dangerouslySetInnerHTML={{__html:this.state.pagetitle}} /> 
                  {this.paginationHtml()}
                  <div className='row'>
                    {(this.state.filteredList.length === 0 && this.state.isloading === false) &&
                      (
                        <p>Currently data doesn't exist, please get in touch if needed</p>
                      )
                    }
                    {this.renderNewTile()}
                  </div>
                  <div className='mb-3'>
                    {this.paginationHtml()}
                  </div>
                  <div className="d-block d-md-none">
                    <TopTrending param={topTrending} />
                  </div>
                </div>
              </div>
              <div className="mt-3 col-md-1 pl-md-2 m-hide-ads">
                <div className='laning-stick'>
                  <CommunityRight />
                </div>
              </div>
            </div>
            <AdsHorizandal />

            <div class="row desktoponly">
              <div class="col-lg-12 ">
                <div class="specialcontent" dangerouslySetInnerHTML={{ __html: this.state.schoolSEOContent}}></div>
              </div>
            </div>

          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
  renderNewTile() {
    return this.state.pageOfItems.map((item, index) => {
      if(index==7){
        return (
          
            <AdsDynamicMobileMiddle />
           
        )
      }
      return (
        <React.Fragment>
          {SCREEN_SIZE < 768 ?
            <Link className='tile-link' to={'/' + this.state.city + '/community-support/' + this.state.subcategory + '/' + item.path_term} onClick={() => this.sub_cate()}>
              {this.getContent(item)}
            </Link>
            :
            this.getContent(item)
          }

        </React.Fragment>
      );
    });
  }
  addTowishList = async (e, data) => {
    e.preventDefault();
    let logged_auth = this.state.logged_auth;
    if (logged_auth == 'true') {
      await addToWishList(data);
    } else {
      this.setState({
        openModalLogin: true
      })
    }
  }

  addToCompare = async (e, data) => {
    e.preventDefault();
    let logged_auth = this.state.logged_auth;
    if (logged_auth == 'true') {
      await addToCompareList(data);
    } else {
      this.setState({
        openModalLogin: true
      })
    }
  }
  getContent = (item) => {
    let fee = item.avg_fee != 0 ? '₹' + Number(item.avg_fee).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 'NA';
    let city = this.props.match.params.city;
    let isMobile = SCREEN_SIZE < 768 ? 'hidden' : '';
    let isDesk = SCREEN_SIZE > 767 ? 'hidden' : '';
    let wishlist = {
      category_id: 121,
      category: this.state.sub_categories,
      id: item._id,
      user_id: this.state.user_id,
      vendor_id: item.vendor_id,
      full_url: window.location.href
    }
    let compare = {
      id: item._id,
      type: 'advice',
      keyword: 'advice_compare'
    }
    return (
      <div className='col-xs-12 col-md-12'>
        <div className='row landing-tile'>
          <div className='col-xs-12 col-lg-12'>
            <div className='row align-items-center d-flex'>
              {item.stamp_img &&
                <div className='tile-stamp-sec col-12'>
                  <img className='tile-stamp-img' src={'https://api.bewise.in/' + item.stamp_img} />
                </div>
              }
              <div className='col-3 pr-0 '>
                <div className='tile-img'>
                  <img src={item.images} />
                </div>
              </div>
              <div className='col-9'>
                <div className='tile-detail-sec tile-title row align-items-center'>
                  <div className='col-lg-10 p-0'>
                    <p className='text-wrap'>{item.name}</p>
                  </div>
                  <div className='col-lg-2 p-0 d-none d-md-block'>
                    <div className='tile-btn-biew pull-right'>
                      <Link target="_blank"  to={'/' + this.state.city + '/community-support/' + this.state.subcategory + '/' + item.path_term} onClick={() => this.sub_cate()}>
                        <button className='btn btn-sm btn-primary'>More Info</button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='tile-detail-sec tile-adrs row'>
                  <p className='text-wrap'>{item.address}</p>
                </div>
                <div className='tile-detail-sec align-items-center row'>
                  <div className='col-lg-3 col-xs-6 p-0'>
                    <div className='tile-distance badge badge-secondary'>
                      <p className='text-wrap'>
                        <span><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;</span>
                        <span>{parseFloat(item.distance).toFixed(1)} KM &nbsp;</span>
                        <span title={item.area_name}>{item.area_name}</span>
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-3 col-xs-6 p-0'>
                    <div className='tile-rate badge badge-secondary'>
                      <p className='text-wrap'>
                        <span><i class="fa fa-star" aria-hidden="true"></i>&nbsp;</span>
                        <span>{item.rating ? item.rating : '0.0'}</span>
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-3 col-xs-6 p-0'>
                    <div className='tile-compare' onClick={(e) => this.addToCompare(e, compare)}>
                      <img src={actionicon1} />&nbsp;Compare
                    </div>
                  </div>
                  <div className='col-lg-3 col-xs-6 p-0'>
                    <div className='tile-wish' onClick={(e) => this.addTowishList(e, wishlist)}>
                      <img src={actionicon3} />&nbsp; Wishlist
                    </div>
                  </div>
                </div>
                <div className={`tile-detail-sec tile-details row ${isMobile}`}>
                  <div className='col-lg-4 col-xs-4 p-0'>
                    <label>Services</label>
                    <p>{item.service}</p>
                  </div>
                  <div className='col-lg-4 col-xs-4 p-0'>
                    <label>Service At</label>
                    <p>{item.serviceat}</p>
                  </div>
                  <div className='col-lg-4 col-xs-4 p-0'>
                    <label>Type</label>
                    <p>{item.partner_type}</p>
                  </div>
                </div>
              </div>
              <div className={`col-12 ${isDesk}`}>
                <div className='tile-detail-sec tile-details row'>
                  <div className='col-4 p-0'>
                    <label>Services</label>
                    <p>{item.service}</p>
                  </div>
                  <div className='col-4 p-0'>
                    <label>Service At</label>
                    <p>{item.serviceat}</p>
                  </div>
                  <div className='col-4 p-0'>
                    <label>Type</label>
                    <p>{item.partner_type}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xs-12 col-lg-12'>
            <div className='row align-items-center'>
              <div className='col-lg-12 col-sm-12'>
                <div className='tile-seo-tag'>
                  <div className={`${isMobile} tile-seo-tag-desk`}>
                    {item.keywords.map((data) => {
                      return (<span class="badge badge-defult">{data}</span>)
                    })}
                  </div>
                  <div className={`${isDesk} tile-seo-tag-mob`}>
                    {item.keywords.map((data) => {
                      return (<span class="badge badge-defult">{data}</span>)
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderList() {

    let sub_cate = this.state.sub_categories.toString();
    console.log('sub_cate', sub_cate)

    if (this.state.filteredList.length === 0 && this.state.isloading === false) {
      return (
        <p>Currently data doesn't exist, please get in touch if needed</p>
      );
    } else {
      return this.state.pageOfItems.map((item) => {
        let fee = item.avg_fee != 0 ? '₹' + Number(item.avg_fee).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' /Month' : 'NA';
        return (
          <div className='col-sm-6 col-md-6 col-xl-4 col-lg-6 hover1' style={{ padding: 10 }} key={item._id}>
            <div
              className='img-school s6g6bd'
              style={{ backgroundImage: `url('${item.images ? item.images : '../assets/icons/1.png'}')` }}
            >
              <div className="conntent-bottom">
                <div className='r5b99d'>
                  <span className='g4yb'>{item.name}</span>
                </div>
                <div className='u0865'>
                  <div className='gf5-review'>
                    <div className='n6cd-r'>
                      <img
                        src={BASE_URL + 'assets/icons/review-star.png'}
                        className='star-r'
                        alt='star  '
                      />
                    </div>
                    {<div className='v5dfa'>{item.rating ? item.rating : '0.0'}</div>}
                  </div>
                  <div className='f5va-label'>
                    <i className='fas fa-map-marker-alt custom'></i>{' '}
                    <span>&nbsp;{parseFloat(item.distance).toFixed(1)} km</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='overlay'>
              <div className='text'>
                <div className='row'>
                  <div className={item.stamp_img ? 'col-md-9' : 'col-md-12'}>
                    <h6 className='school-n6f'>{item.name}</h6>
                  </div>
                  {item.stamp_img &&
                    <div className='col-md-3 stamp-sec'>
                      <img src={IMG_URL + item.stamp_img} />
                    </div>
                  }
                </div>
                <p>
                  <span className='n7v5'>Place</span> : {item.location}
                </p>
                <p>
                  <span className='n7v5'>Avg Fee</span> : {fee}
                </p>
                <p className="capitalize">
                  <span className='n7v5'>Establishment year</span> : {item.established_year}
                </p>

                <Link to={'/' + this.props.match.params.city + '/community-support/' + this.props.match.params.subcategory + '/' + item.path_term} onClick={() => this.sub_cate()}>
                  <button className='btn hover-btn'>View More</button>
                </Link>
              </div>
            </div>
          </div>
        );
      });
    }

  }
  paginationHtml = () => {
    if (this.state.pageOfItems.length > 0)
      return (
        <div className='middlelist row paginations'>
          <div class="col-8 p-0" ><div className="pageof">{this.state.pageindex} of {this.state.totalsplitpages} Pages<span className="totalrecordsinfo">({this.state.totalrecords} total records)</span></div></div>
          <div class="col-4 p-0">
            <div class="pull-right">
              <div className={this.state.pgnumber == 0 ? "previouslist hidden" : "previouslist"} onClick={() => this.onChangePrevPage(this.state.pgnumber)}>
                <div class="vertical-prev">Prev</div>
              </div>
              <div className={this.state.pageindex == this.state.totalsplitpages ? "nextlist hidden" : "nextlist"}  onClick={() => this.onChangeNextPage(this.state.pgnumber)}>
                <div class="vertical-next">Next</div>
              </div>
            </div>
          </div>
        </div>
      )
  }
  filterFunction = (type) => {
    return (
      <div className="left-side-body mob-filter">
        <div className='f-b6fd'>
          <div className='n8ds-head'>
            <p>
              {FIL_DIS} <i class="fa fa-info-circle" data-tip data-for="registerTip"></i>
            </p>
          </div>
          <div className="App">
            <ReactTooltip id="registerTip" place="bottom" effect="solid" className='react_tooltip_location'>
              <p style={{ textAlign: "left" }}>Default location is {this.state.centerLocation}.<br /> Please choose your own for sharper results.</p>
            </ReactTooltip></div>
          <div
            className={'arrow-head fa ' + this.state.dis_arrow}
            onClick={this.handle_Distance}
          ></div>
        </div>
        {this.state.distance_option === true ? (
          <div className='filter-a1'>
            <PlacesAutocomplete
              value={this.state.address}
              className="form-control"
              searchOptions={{
                location: new window.google.maps.LatLng(localStorage.getItem("latitude"), localStorage.getItem("longitude")),
                radius: 15000,
                types: ['address'],
                strictbounds: true,
                componentRestrictions: {
                  country: 'in'
                }
              }}
              onChange={this.handleplaceChange}
              onSelect={this.handleplaceSelect}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div >
                  <input
                    {...getInputProps({
                      placeholder: 'Input Location ...',
                      className: 'form-control',

                    })}
                    value={this.state.address}
                  />
                  <div className="autocomplete-dropdown-container"
                    style={{ position: "absolute", zIndex: "999" }}>
                    {loading && <div>Loading...</div>}
                    {console.log("suggessions", suggestions)}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>


            <div className='f-nydf range-wrap'>
              <p className="range-text"><span className="col">0</span> <span className="col text-center">To</span> <span className="col text-right">{MAXDIS_FILP}</span></p>
              <input
                id="myRange"
                type="range"
                name='disRange'
                className='slider'
                min="0" max={this.state.disMax}
                value={this.state.distance}
                onChange={this.handleDistanceChange}
                step="1" />
              <output className="bubble" style={{ 'left': 'calc(96% + -0.5px)' }}>{this.state.distance}</output>

            </div>

          </div>) : (
          ''
        )}

        <div className='f-b6fd'>
          <div className='n8ds-head'>
            <p>{FIL_FEEP}</p>
          </div>
          <div
            className={'arrow-head fa ' + this.state.fee_arrow}
            onClick={this.handle_Fees}
          ></div>
        </div>

        <div className='filter-a1'>
          {this.state.fees_option === true ? (
            <div className='f-nydf range-wrap'>
              <p className="range-text"><span className="col">0</span> <span className="col text-center">To</span> <span className="col text-right">{MAXFEE_FILP}</span></p>
              <input
                id="myRange"
                type="range"
                className='slider'
                name="fee_range"
                min="0" max={MAXFEE_FILPlay1}
                value={this.state.annual_fee}
                onChange={this.handleAnnualfeeChange}
                step="1" />
              <output className="bubble" style={{ 'left': 'calc(90% + -0.5px)' }}>{this.state.annual_fee}</output>

            </div>
          ) : (
            ''
          )}
        </div>
        <div className='f-b6fd'>
          <div className='n8ds-head'><p>Rating @ BeWise</p></div>
          <div
            className={'arrow-head fa ' + this.state.review_arrow}
            onClick={this.handle_Review}
          ></div>
        </div>
        <div className='filter-a1 bewise-range-color'>
          {this.state.review_option === true ? (
            <div className='f-nydf range-wrap'>
              <p className="range-text"><span className="col">0</span>
                <span className="col text-center"></span> <span className="col text-right">5</span></p>
              <input
                id="myRange"
                type="range"
                className='slider'
                name="review"
                min="0" max='5'
                value={this.state.review}
                onChange={this.handleRatingChange}
                step="1" />
              <output className="bubble" style={{ left: 'calc(0% + 8px)' }}>{this.state.review == 5 ? this.state.review : this.state.review + '+'}</output>
            </div>) : ''}

        </div>

        <div className='f-b6fd'>
          <div className='n8ds-head'><p>{RATING_GOOGLE}</p></div>
          <div
            className={'arrow-head fa ' + this.state.reviews_arrow}
            onClick={this.handle_Reviews}
          ></div>
        </div>
        <div className='filter-a1 bewise-range-color'>
          {this.state.reviews_option === true ? (
            <div className='f-nydf range-wrap'>
              <p className="range-text"><span className="col">0</span>
                <span className="col text-center"></span> <span className="col text-right">5</span></p>
              <input
                id="myRange"
                type="range"
                className='slider'
                name="review_social"
                min="0" max='5'
                value={this.state.review_social}
                onChange={this.onStarClickSocial}
                step="1" />
              <output className="bubble" style={{ left: 'calc(4% + 4.25px)' }}>{this.state.review_social == 5 ? this.state.review_social : this.state.review_social + '+'}</output>
            </div>) : ''}
        </div>

        <React.Fragment>
          <div className='f-b6fd'>
            <div className='n8ds-head'><p>Learning Location</p></div>
            <div
              className={'arrow-head fa ' + this.state.learn_arrow}
              onClick={this.handle_learning}
            ></div>
          </div>

          <div className='filter-a1'>
            {this.state.learning_option === true ? (
              <ul className='f-li-none'>
                {this.state.schooltypeList.length > 0 &&
                  this.state.schooltypeList.map((item, i) => {
                    let check = (this.state.affilation.includes(item.id.toString())) ? 'checked' : '';
                    if (item.name != 'NA')
                      return (
                        <li value={item.id} >
                          <input
                            type='checkbox'
                            onChange={this.handleLearningCheckbox}
                            name='affilation'
                            value={item.id}
                            defaultChecked={check}
                          />
                          {item.name}
                        </li>
                      )
                  })}
              </ul>
            ) : (
              ''
            )}
          </div>
          <div className='f-b6fd'>
            <div className='n8ds-head'><p>Type</p></div>
            <div
              className={'arrow-head fa ' + this.state.ptype_arrow}
              onClick={this.handle_ptype}
            ></div>
          </div>

          <div className='filter-a1'>
            {this.state.ptype_option === true ? (
              <ul className='f-li-none'>
                <li value={1} >
                  <input
                    type='checkbox'
                    onChange={this.handlePtypeCheck}
                    name='partner_type'
                    value={1}
                    defaultChecked={this.state.partner_type.includes('1') ? 'checked' : ''}
                  />
                  Institution
                </li>
                <li value={2} >
                  <input
                    type='checkbox'
                    onChange={this.handlePtypeCheck}
                    name='partner_type'
                    value={2}
                    defaultChecked={this.state.partner_type.includes('2') ? 'checked' : ''}
                  />
                  Individuals
                </li>
              </ul>
            ) : (
              ''
            )}
          </div>
          {/* <div className='f-b6fd'>
                        <div className='n8ds-head'>
                          <p>Service</p>
                        </div>
                        <div
                          className={'arrow-head fa ' + this.state.service_arrow}
                          onClick={this.handle_Service}
                        ></div>
                      </div> */}
          <div className='filter-a1'>
            {this.state.service_option === true ? (

              <Multiselect
                options={this.state.options} // Options to display in the dropdown
                selectedValues={this.state.selectedValues} // Preselected value to persist in dropdown
                onSelect={this.onSelect} // Function will trigger on select event
                onRemove={this.onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />

            ) : (
              ''
            )}
          </div>

        </React.Fragment>

        {type == 1 ? (
          <div className='filter-a1 text-center'>
            <hr />
            <button class="btn btn-theme btn-sm" style={{
              background: 'rgb(245 209 112)',
              color: '#fff'
            }} onClick={this.filterToogle}>Apply</button>
          </div>
        ) :
          (
            <div className='filter-a1 text-center'>
              <hr />
              <button class="btn btn-theme btn-sm" style={{
                background: 'rgb(245 209 112)',
                color: '#fff'
              }} onClick={this.handle_clearFilter}>Clear Filter</button>
            </div>
          )
        }
      </div>
    )
  }
}

export default CommunitySupports;
